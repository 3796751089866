<template>
  <a-page-header
    @back="() => $router.go(-1)"
    title="GRÁFICO DE GESTANTES CON ANEMIA"
    class="pt-0 px-0"
  >
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Nutricional</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Gráfica de niños con anemia</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <fieldset
      class="border border-solid border-gray-300 justify-center items-center w-full flex px-8 pb-3 lg:w-auto"
    >
      <legend class="text-sm text-left pl-4">Filtros</legend>
      <a-form class="md:flex w-full gap-4" ref="formRefFilter" layout="vertical" :model="formState">
        <a-form-item label="Año" name="search" class="mb-0 w-full lg:w-44">
          <a-select class="w-56" @select="onSelectPeriodo" v-model:value="formState.anio">
            <a-select-option v-for="anio in anios" :value="anio" :key="anio">
              {{ anio }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <button @click="cleanFilters" type="button" class="text-center">
          <div class="text-3xl text-purple-500">
            <ClearOutlined />
          </div>
          <p>Limpiar filtros</p>
        </button>
      </a-form>
    </fieldset>

    <fieldset
      class="border border-solid w-full mt-3 border-gray-300 p-3 px-8 flex justify-center md:mt-0 lg:w-auto"
    >
      <legend class="text-sm text-center">Acciones</legend>
      <div class="flex flex-wrap gap-4">
        <div class="flex flex-wrap gap-8 md:gap-5">
          <button @click="showModalPrintModal" class="text-center">
            <div class="text-3xl" :class="'text-red-400'">
              <FilePdfOutlined />
            </div>
            Descargar
          </button>
        </div>
      </div>
    </fieldset>

    <fieldset
      class="border border-solid w-full mt-3 border-gray-300 p-3 px-8 flex justify-center md:mt-0 lg:w-auto"
    >
      <legend class="text-sm pl-5">Leyenda</legend>
      <div class="flex flex-wrap gap-4">
        <div v-for="escala in escalaColores" :key="escala.texto" class="flex gap-2 items-center">
          <div
            class="w-3 h-3 rounded-md border border-gray-500 border-none"
            :style="{ backgroundColor: escala.color }"
          ></div>
          {{ escala.texto }}
        </div>
      </div>
    </fieldset>
  </div>

  <div class="flex gap-4 flex-wrap lg:flex-nowrap w-full">
    <div v-if="!isLoadingProvincia" class="w-full lg:w-1/2">
      <a-card size="small">
        <template #title>
          <h1 class="text-center font-semibold text-lg">Provincia</h1>
        </template>
        <v-chart
          @click="clickProvincia"
          :option="dataProvincias"
          style="width: 100%; height: 250px"
        />
      </a-card>
    </div>

    <div v-if="!isLoadingDistrito" class="w-full lg:w-1/2 overflow-x-auto">
      <div class="w-144 lg:w-full">
        <a-card size="small">
          <template #title>
            <div class="text-center">
              <h1 class="font-semibold text-lg">Distrito</h1>
              <p v-if="provinciaFiltrado" class="text-sm text-gray-500">
                <FilterOutlined />Provincia {{ provinciaFiltrado }}
              </p>
            </div>
          </template>
          <v-chart
            @click="clickDistrito"
            :option="dataDistritos"
            :style="{ height: heightDistrito + 'px' }"
            style="width: 100%"
          />
        </a-card>
      </div>
    </div>
  </div>

  <div v-if="!isLoadingEstablecimiento" class="w-full overflow-x-scroll">
    <div class="w-144 lg:w-full">
      <a-card size="small">
        <template #title>
          <div class="text-center">
            <h1 class="font-semibold text-lg">Establecimiento</h1>
            <p v-if="distritoFiltrado" class="text-sm text-gray-500">
              <FilterOutlined />distrito {{ distritoFiltrado }}
            </p>
          </div>
        </template>

        <v-chart
          :option="dataEstablecimiento"
          :style="{ height: heightEstablecimiento + 'px' }"
          style="width: 100%"
        />
      </a-card>
    </div>
  </div>

  <p class="text-gray-400 my-4">Fuente: SIEN actualizado a la fecha: {{ fechaIngresoTabla }}</p>

  <!-- ------------------------ Impresión ------------------------ -->
  <a-modal
    class="max-w-sm md:max-w-5xl text-center"
    width="100%"
    :visible="isVisiblePrintModal"
    :footer="null"
    @close="closeModalPrintModal"
    @cancel="closeModalPrintModal"
  >
    <a-button
      shape="round"
      v-print="objPrintNutricionalGrafica"
      size="large"
      class="text-center mx-auto mb-8 bg-red-500 text-white"
    >
      <template #icon>
        <PrinterOutlined />
        Imprimir
      </template>
    </a-button>

    <div id="printNutricionalGrafica">
      <PrintHeader />

      <div class="text-center font-bold text-lg my-4">
        GRÁFICO DE NIÑOS Y NIÑAS DE 6 A 36 MESES DE EDAD CON ANEMIA
      </div>

      <div class="flex flex-wrap gap-4">
        <div v-for="escala in escalaColores" :key="escala.texto" class="flex gap-2 items-center">
          <div
            class="w-3 h-3 rounded-md border border-gray-500 border-none"
            :style="{ backgroundColor: escala.color }"
          ></div>
          {{ "(" + escala.nombreColor + ") " + escala.texto }}
        </div>
      </div>

      <div class="w-full mb-8">
        <a-card size="small">
          <template #title>
            <h1 class="text-center font-semibold text-lg">Provincia</h1>
          </template>
          <v-chart :option="dataProvincias" style="width: 100%; height: 250px" />
          <p class="text-gray-400 my-4">
            Fuente: SIEN actualizado a la fecha: {{ fechaIngresoTabla }}
          </p>
        </a-card>
      </div>

      <div class="w-full mb-20">
        <a-card size="small">
          <template #title>
            <h1 class="text-center font-semibold text-lg">Distrito</h1>
            <p v-if="provinciaFiltrado" class="text-sm text-gray-500">
              <FilterOutlined />Provincia {{ provinciaFiltrado }}
            </p>
          </template>
          <v-chart
            :option="dataDistritos"
            :style="{ height: heightDistrito + 'px' }"
            style="width: 100%"
          />
          <p class="text-gray-400 my-4">
            Fuente: SIEN actualizado a la fecha: {{ fechaIngresoTabla }}
          </p>
        </a-card>
      </div>

      <div class="w-full">
        <a-card size="small">
          <template #title>
            <h1 class="text-center font-semibold text-lg">Establecimiento</h1>
            <p v-if="distritoFiltrado" class="text-sm text-gray-500">
              <FilterOutlined />distrito {{ distritoFiltrado }}
            </p>
          </template>

          <v-chart
            :option="dataEstablecimiento"
            :style="{ height: heightEstablecimiento + 'px' }"
            style="width: 100%"
          />
          <p class="text-gray-400 my-4">
            Fuente: SIEN actualizado a la fecha: {{ fechaIngresoTabla }}
          </p>
        </a-card>
      </div>
    </div>
  </a-modal>
</template>

<script>
import PacienteReporteApi from "@/api/nutricionalreporte";
import PrintHeader from "@/components/PrintHeader.vue";
import UsuariosApi from "@/api/user";
import MapaApi from "@/api/mapa";
import { onMounted, ref, toRaw, reactive, watch } from "vue";
import { generaGraficoBarra } from "./utilsNutricionalGraficas";
import { useStore } from "vuex";
import {
  FilePdfOutlined,
  ClearOutlined,
  PrinterOutlined,
  FilterOutlined
} from "@ant-design/icons-vue";
import { generaLeyendaSemaforoPorcentaje } from "@/utils";
import moment from "moment";

export default {
  components: {
    PrintHeader,
    // icons
    PrinterOutlined,
    FilePdfOutlined,
    ClearOutlined,
    FilterOutlined
  },
  setup() {
    const provincias = ref(null);
    const distritos = ref(null);
    const centrosPoblados = ref(null);
    const heightDistrito = ref("800");
    const anios = ref([]);
    const heightEstablecimiento = ref("900");
    const isLoadingProvincia = ref(false);
    const isLoadingDistrito = ref(false);
    const isLoadingEstablecimiento = ref(false);
    const isVisiblePrintModal = ref(false);
    const isDisabled = ref(true);
    const fechaIngresoTabla = ref("");

    const dataProvincias = ref(null);
    const dataDistritos = ref(null);
    const dataEstablecimiento = ref(null);

    const provinciaFiltrado = ref("");
    const distritoFiltrado = ref("");

    const escalaColores = ref([]);
    const coloresSemaforo = reactive({
      PORCENTAJE_BAJO: "10",
      PORCENTAJE_MEDIO: "20",
      PORCENTAJE_ALTO: "30"
    });

    const ACTUAL_YEAR = new Date().getFullYear(); // returns the current year

    const store = useStore();

    const { establecimiento } = store.state.user.usuario;

    const formState = reactive({
      search: "porcentaje_normal",
      anio: ACTUAL_YEAR
    });

    const objPrintNutricionalGrafica = reactive({
      id: "printNutricionalGrafica",
      popTitle: "SIVINUR | GRÁFICAS DE INDICADOR"
    });

    const fetchProvincias = () => {
      isLoadingProvincia.value = true;

      PacienteReporteApi.getAllProvincia(toRaw(formState.anio))
        .then(async (response) => {
          const ejeX = response.data.map((d) => d.nombre);
          const porcentaje = response.data.map((d) => Number(d.resultado).toFixed(2));

          dataProvincias.value = generaGraficoBarra(ejeX, porcentaje, coloresSemaforo);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoadingProvincia.value = false;
        });
    };

    const fetchDistrito = () => {
      isLoadingDistrito.value = true;

      const provincia = store.getters["pacientereporte/provincia"];
      PacienteReporteApi.getAllDistrito(provincia, toRaw(formState.anio))
        .then((response) => {
          // console.log(response);
          const ejeX = response.data.map((d) => d.nombre);
          const porcentaje = response.data.map((d) => Number(d.resultado).toFixed(2));

          dataDistritos.value = generaGraficoBarra(ejeX, porcentaje, coloresSemaforo);
          heightDistrito.value =
            ejeX.length > 12
              ? "800"
              : ejeX.length > 8
              ? "500"
              : ejeX.length > 5
              ? "400"
              : ejeX.length > 3
              ? "300"
              : "250";
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoadingDistrito.value = false;
        });
    };

    const fetchEstablecimiento = () => {
      isLoadingEstablecimiento.value = true;
      const provincia = store.getters["pacientereporte/provincia"];
      const distrito = store.getters["pacientereporte/distrito"];

      PacienteReporteApi.getAllEstablecimiento(provincia, distrito, toRaw(formState.anio))
        .then((response) => {
          // console.log(response);
          const ejeX = response.data.map((d) => d.nombre);
          const porcentaje = response.data.map((d) => Number(d.resultado).toFixed(2));

          dataEstablecimiento.value = generaGraficoBarra(ejeX, porcentaje, coloresSemaforo);

          heightEstablecimiento.value =
            ejeX.length > 40
              ? "2000"
              : ejeX.length > 18
              ? "1000"
              : ejeX.length > 12
              ? "800"
              : ejeX.length > 8
              ? "500"
              : ejeX.length > 5
              ? "400"
              : ejeX.length > 3
              ? "300"
              : "150";
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoadingEstablecimiento.value = false;
        });
    };

    const getEscalaColoresMapa = () => {
      MapaApi.getSemaforoMapa()
        .then((res) => {
          // console.log(res);

          coloresSemaforo.PORCENTAJE_BAJO = res.data.find(
            (p) => p.denominacion === "MAPA_PORCENTAJE_BAJO"
          ).valor;
          coloresSemaforo.PORCENTAJE_MEDIO = res.data.find(
            (p) => p.denominacion === "MAPA_PORCENTAJE_MEDIO"
          ).valor;
          coloresSemaforo.PORCENTAJE_ALTO = res.data.find(
            (p) => p.denominacion === "MAPA_PORCENTAJE_ALTO"
          ).valor;

          escalaColores.value = generaLeyendaSemaforoPorcentaje(toRaw(coloresSemaforo));
        })
        .catch((err) => {
          console.log(err);
        });
    };

    watch(store.state.pacientereporte, () => {
      provinciaFiltrado.value = store.state.pacientereporte.provincia;
      distritoFiltrado.value = store.state.pacientereporte.distrito;
    });

    const fetchFechaImportacion = () => {
      UsuariosApi.getParametroxDenominacion("FECHA_SIEN_NUTRICIONAL")
        .then((res) => {
          // console.log(res.data[0].valor);
          fechaIngresoTabla.value = res.data[0].valor;
        })
        .catch((err) => console.log(err));

      // UsuariosApi.getFechaImportacion("sien_anemia_new")
      //   .then((res) => {
      //     fechaIngresoTabla.value = moment(res.data[0]?.fechaingreso).format("DD/MM/YYYY");
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Nutricional", "Nutricional Graficas", establecimiento);
      var anio = moment(new Date()).local().format("YYYY");

      var oldanios = [];
      for (let index = 0; index < 2; index++) {
        oldanios.push(anio - index);
      }
      anios.value = oldanios;

      store.dispatch("pacientereporte/cleanFilters");

      getEscalaColoresMapa();

      fetchProvincias();
      fetchDistrito();
      fetchEstablecimiento();
      fetchFechaImportacion();
    });

    function clickProvincia(params) {
      store.dispatch("pacientereporte/setprovincia", params.name);
      store.dispatch("pacientereporte/setdistrito", "");
      fetchDistrito();
    }

    function clickDistrito(params) {
      store.dispatch("pacientereporte/setdistrito", params.name);
      fetchEstablecimiento();
    }

    const onSelectPeriodo = () => {
      store.dispatch("pacientereporte/cleanFilters");
      fetchProvincias();
      fetchDistrito();
      fetchEstablecimiento();
    };

    const reloadGraficas = () => {
      store.dispatch("pacientereporte/cleanFilters");
      fetchProvincias();
      fetchDistrito();
      fetchEstablecimiento();
    };

    watch(formState, () => {
      isDisabled.value = formState.search === "porcentaje_normal" && formState.anio === ACTUAL_YEAR;
    });

    const cleanFilters = () => {
      formState.search = "porcentaje_normal";
      formState.anio = ACTUAL_YEAR;

      store.dispatch("pacientereporte/cleanFilters");

      fetchProvincias();
      fetchDistrito();
      fetchEstablecimiento();

      console.log("Limpiar filtros");
    };

    const showModalPrintModal = () => {
      isVisiblePrintModal.value = true;
    };
    const closeModalPrintModal = () => {
      isVisiblePrintModal.value = false;
    };

    return {
      clickProvincia,
      anios,
      onSelectPeriodo,
      clickDistrito,
      provincias,
      distritos,
      centrosPoblados,
      formState,
      heightDistrito,
      heightEstablecimiento,
      isLoadingProvincia,
      isLoadingDistrito,
      isLoadingEstablecimiento,
      reloadGraficas,
      dataProvincias,
      dataDistritos,
      dataEstablecimiento,
      cleanFilters,
      isDisabled,
      escalaColores,
      isVisiblePrintModal,
      showModalPrintModal,
      closeModalPrintModal,
      objPrintNutricionalGrafica,
      provinciaFiltrado,
      distritoFiltrado,
      fechaIngresoTabla
    };
  }
};
</script>

<style></style>
